import React from "react";
import { IconProps as ChakraIconProps } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";

const PixelMoon = ({ ...rest }: ChakraIconProps) => {
  return (
    <Icon {...rest}>
      {/*<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      <path d="M11 14.3997H9.42859V15.9997H11V14.3997Z" fill="#180E30" />
      <path d="M12.5715 16H11.0001V17.6H12.5715V16Z" fill="#180E30" />
      <path d="M12.5715 6.39978H11.0001V7.99978H12.5715V6.39978Z" fill="#180E30" />
      <path
        d="M14.1427 22.3998H12.5713H10.9999H9.42842H7.85699V23.9998H9.42842H10.9999H12.5713H14.1427H15.7141H17.2856V22.3998H15.7141H14.1427Z"
        fill="#180E30"
      />
      <path
        d="M10.9999 1.6H12.5713H14.1427H15.7141H17.2856V0H15.7141H14.1427H12.5713H10.9999H9.42842H7.85699V1.6H9.42842H10.9999Z"
        fill="#180E30"
      />
      <path
        d="M14.1428 19.2003H15.7142H17.2856H18.8571H20.4285V20.8003H21.9999V19.2003V17.6003H20.4285H18.8571H17.2856H15.7142H14.1428H12.5714V19.2003H14.1428Z"
        fill="#180E30"
      />
      <path d="M17.2856 20.8001V22.4001H18.857H20.4284V20.8001H18.857H17.2856Z" fill="#180E30" />
      <path d="M4.71419 19.1999H3.14276V20.7999H4.71419V19.1999Z" fill="#180E30" />
      <path d="M4.71419 3.19987H3.14276V4.79988H4.71419V3.19987Z" fill="#180E30" />
      <path
        d="M20.4285 3.19987V4.79987H18.8571H17.2856H15.7142H14.1428H12.5714V6.39987H14.1428H15.7142H17.2856H18.8571H20.4285H21.9999V4.79987V3.19987H20.4285Z"
        fill="#180E30"
      />
      <path d="M3.14296 16H1.57153V17.6V19.2H3.14296V17.6V16Z" fill="#180E30" />
      <path d="M3.14296 6.40017V4.80017H1.57153V6.40017V8.00017H3.14296V6.40017Z" fill="#180E30" />
      <path d="M1.57143 12.8V11.2V9.6V8H0V9.6V11.2V12.8V14.4V16H1.57143V14.4V12.8Z" fill="#180E30" />
      <path d="M7.85699 11.2003V12.8003V14.4003H9.42842V12.8003V11.2003V9.60033H7.85699V11.2003Z" fill="#180E30" />
      <path d="M11 8H9.42859V9.6H11V8Z" fill="#180E30" />
      <path d="M6.28572 20.8001H4.71429V22.4001H6.28572H7.85715V20.8001H6.28572Z" fill="#180E30" />
      <path d="M18.857 3.20027H20.4284V1.60027H18.857H17.2856V3.20027H18.857Z" fill="#180E30" />
      <path d="M7.85715 3.20027V1.60027H6.28572H4.71429V3.20027H6.28572H7.85715Z" fill="#180E30" />
      {/*</svg>*/}
    </Icon>
  );
};

export default PixelMoon;
