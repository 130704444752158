import { IconProps as ChakraIconProps } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";

const ToolErase = ({ ...rest }: ChakraIconProps) => {
  return (
    <Icon {...rest} px={0.5}>
      {/*<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">*/}
      <path d="M 1.993 19.98 L 7.987 19.98 L 7.987 18 L 5.979 18 L 5.979 15.984 L 3.992 15.984 L 3.992 13.984 L 5.988 13.985 L 5.988 11.988 L 7.984 11.988 L 7.984 11.988 L 7.99 9.99 L 9.983 9.99 L 9.983 7.99 L 11.976 7.99 L 11.976 5.994 L 13.969 5.994 L 13.969 7.994 L 11.976 7.994 L 11.976 9.99 L 13.972 9.99 L 13.972 15.984 L 11.976 15.984 L 11.976 17.982 L 9.98 17.982 L 9.98 19.98 L 21.956 19.98 L 21.956 22 L 1.993 22 L 1.993 19.98 Z M 15.971 1.998 L 17.964 1.998 L 17.964 3.998 L 15.971 3.998 L 15.971 1.998 Z M 13.975 3.996 L 15.968 3.996 L 15.968 5.996 L 13.975 5.996 L 13.975 3.996 Z M 13.975 11.986 L 15.968 11.986 L 15.968 9.99 L 17.961 9.99 L 17.961 11.99 L 15.968 11.99 L 15.968 13.986 L 13.975 13.986 L 13.975 11.986 Z M 17.967 3.996 L 19.96 3.996 L 19.96 5.992 L 21.953 5.992 L 21.953 7.992 L 19.96 7.992 L 19.96 9.99 L 17.967 9.99 L 17.967 7.99 L 19.96 7.99 L 19.96 5.996 L 17.967 5.996 L 17.967 3.996 Z" />
      {/*</svg>*/}
    </Icon>
  );
};

export default ToolErase;
